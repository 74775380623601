// eslint-disable-next-line import/no-unresolved
import { Container, Box } from '@mui/material';

const stepStyle = {
    backgroundColor: '#d8d8d8',
    height: '100%',
    position: 'relative',
    margin: '0 1px',
    flex: '1 0 auto',
    '&:before': {
        content: '" "',
        position: 'absolute',
        width: 0,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'primary.dark',
    },
    '&[data-fill="true"]:before': {
        width: '100%',
    },
    '&[data-current="true"]:before': {
        transition: '.3s width ease-out',
        width: '100%',
    },
};

const Stepper = ({ step, steps = 7 }) => {
    const displaySteps = () => {
        const stepper = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i <= steps; i++) {
            stepper.push(<Box key={`step-${i}`} sx={stepStyle} data-fill={i < step} data-current={step === i} />);
        }
        return stepper;
    };

    return (
        <Container
            sx={{
                px: 3,
                pt: 3,
                position: 'absolute',
                top: 0,
            }}
        >
            <Box
                sx={{
                    height: 3,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'stretch',
                }}
            >
                {displaySteps()}
            </Box>
        </Container>
    );
};
export default Stepper;
