// eslint-disable-next-line import/no-unresolved
import { getAuth, signOut } from 'firebase/auth';

// eslint-disable-next-line import/prefer-default-export
export const onFinish = async (returnTo, refreshToken = null) => {
    const env = process.env.REACT_APP_ENV || 'DEV';
    await signOut(getAuth());

    if (returnTo) {
        window.location.href = `${returnTo}?refreshToken=${refreshToken}`;
        return;
    }

    const domain = env === 'PROD' ? 'https://account.porschedrive.ca' : 'https://porsche-fo-test.vulog.center';
    window.location.href = `${domain}/account?apps=true&success=true&app_notify=1&closeview=true&refreshToken=${refreshToken}`;
};
