// eslint-disable-next-line import/no-unresolved
import React from 'react';
// eslint-disable-next-line import/no-unresolved
import { CircularProgress, Grid, Box } from '@mui/material';

const Loading = ({ over }) => (
    <>
        {!over && (
            <Grid
                container
                direction="column"
                spacing={0}
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: '100vh' }}
            >
                <CircularProgress />
            </Grid>
        )}
        {over && (
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 999999,
                    background: 'rgba(255, 255, 255, 0.5)',
                }}
            >
                <Grid
                    container
                    direction="column"
                    spacing={0}
                    alignItems="center"
                    justifyContent="center"
                    sx={{ minHeight: '100vh' }}
                >
                    <CircularProgress />
                </Grid>
            </Box>
        )}
    </>
);

export default Loading;
